import React from 'react'
import TeamMember from 'src/components/TeamMember'
import { TeamMemberQueryResult } from 'src/model/teamMemberQuery'
import { css } from 'src/style/css'
import { Theme } from 'src/style/Theme'
import { grid } from 'src/style/mixins'

type TeamGridProps = {
    items: TeamMemberQueryResult[]
}

const TeamGrid = ({ items }: TeamGridProps) => {
    return (
        <div
            css={(th: Theme) => css`
                ${grid({ minWidth: '18em', gap: th.spacing.lg })}
                justify-items: center;
                @media screen and (max-width: 52em) {
                    grid-template-columns: repeat(
                        auto-fit,
                        minmax(16em, calc(100%))
                    );
                }
            `}
        >
            {items.map((item) => (
                <TeamMember {...item} key={item.id} />
            ))}
        </div>
    )
}

export default TeamGrid

import React, { useState } from 'react'
import Header from 'src/components/Header'
import { css } from 'src/style/css'
import { TeamMemberQueryResult } from 'src/model/teamMemberQuery'
import { useTheme } from '@emotion/react'
import { gatsbyImgDataToHTMLImage } from 'src/components/Image'
import Icon from 'src/icons'
import { Theme } from 'src/style/Theme'

export type TeamMemberProps = Omit<TeamMemberQueryResult, 'id'>

const primaryIconStyle = (th: Theme) => css`
    color: ${th.colors.primary};
    width: ${th.fontSize.xl};
    height: ${th.fontSize.xl};
    margin-left: ${th.spacing.xs};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 45px;
    &:hover {
        color: ${th.colors.primary};
        opacity: 0.9;
    }
`

const TeamMember = ({ name, photos, position, linkedin }: TeamMemberProps) => {
    const [activePhotoIndex, setActivePhotoIndex] = useState(0)
    const [intervalValue, setIntervalValue] = useState<any>(null)
    const imageData = gatsbyImgDataToHTMLImage(photos[activePhotoIndex])
    const th = useTheme()

    return (
        <div
            css={css`
                text-align: center;
            `}
        >
            <img
                src={imageData.src}
                sizes={imageData.sizes}
                width="auto"
                height="300"
                srcSet={imageData.srcSet}
                alt={name}
                loading="lazy"
                css={css`
                    width: 100%;
                    margin: 0 0 ${th.spacing.md};
                    object-fit: contain;
                `}
                onMouseEnter={() => {
                    setActivePhotoIndex((prevIndex) =>
                        prevIndex + 1 <= photos.length - 1
                            ? prevIndex + 1
                            : prevIndex
                    )
                    setIntervalValue(
                        setInterval(() => {
                            setActivePhotoIndex((prevIndex) =>
                                prevIndex === photos.length - 1
                                    ? 0
                                    : prevIndex + 1
                            )
                        }, 1000)
                    )
                }}
                onMouseLeave={() => {
                    intervalValue && clearInterval(intervalValue)
                    setIntervalValue(null)
                    setActivePhotoIndex(0)
                }}
            />
            <div
                css={css`
                    margin: 0 0 ${th.spacing.sm};
                    color: ${th.colors.darkGray};
                `}
            >
                <Header size={3} uppercase>
                    {name}
                </Header>
            </div>
            <span
                css={css`
                    margin: 0;
                    color: ${th.colors.secondary};
                    font-family: ${th.fonts.primary};
                    font-size: ${th.fontSize.sm};
                    display: inline-block;
                    // Keeps component same height as others
                    height: 2.6em;
                    line-height: 1.3;
                `}
            >
                {position}
            </span>
            <div
                css={css`
                    display: flex;
                    justify-content: center;
                `}
            >
                <a
                    title="LinkedIn"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={linkedin}
                    css={primaryIconStyle}
                    aria-label={'LinkedIn'}
                >
                    <Icon.LinkedinV2 />
                </a>
            </div>
        </div>
    )
}

export default TeamMember

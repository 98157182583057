import React, { ReactNode } from 'react'
import { css } from 'src/style/css'
import { FontSizeKey, Theme } from 'src/style/Theme'

type Level = 1 | 2 | 3 | 4

type Header = {
    uppercase?: boolean
    as?: 'span' | 'h1'
    size: Level
    children: ReactNode
}

const fontSizeMap: { [key in Level]: FontSizeKey } = {
    1: 'xl',
    2: 'lg',
    3: 'md',
    4: 'sm'
}

const getFontSize = (th: Theme, size: Level) => {
    const r = th.fontSize[fontSizeMap[size]]
    if (!r) throw new Error(`Unsupported Header Size of value ${size}`)
    return r
}

const Header = ({
    uppercase = false,
    as = 'span',
    size,
    children: value
}: Header) => {
    const Component = as
    return (
        <Component
            css={(th: Theme) => css`
                display: inline-block;
                font-weight: ${th.fontWeight.semiBold};
                font-size: ${getFontSize(th, size)};
                text-transform: ${uppercase ? 'uppercase' : 'none'};
            `}
        >
            {value}
        </Component>
    )
}

export default Header

import { TeamMemberInfo } from 'src/model/TeamMemberType'
import { ImageSrc } from 'src/components/Image'
import { graphql } from 'gatsby'

export type TeamMemberQueryResult = TeamMemberInfo & {
    photos: ImageSrc[]
}

export type WrappedTeamMemberQueryResult = {
    members: {
        id: TeamMemberQueryResult
    }[]
}

export const unwrapTeamMemberFromQuery = (
    r: WrappedTeamMemberQueryResult
): TeamMemberQueryResult[] => r.members.map((i) => i.id)

export const teamMemberFragment = graphql`
    fragment TeamMembers on TeamOrderJson {
        members {
            id {
                id
                path
                photos {
                    ...SrcImages_noBlur
                }
                name
                position
                linkedin
            }
        }
    }
`

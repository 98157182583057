import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { ImageDataLike } from 'gatsby-plugin-image/dist/src/components/hooks'

export type ImageSrc = ImageDataLike

export type Image = {
    src: ImageSrc
    alt: string
    className?: string
    backgroundColor?: string
    onMouseEnter?: () => void
    onMouseLeave?: () => void
    loading?: 'eager'
}

export const gatsbyImgDataToHTMLImage = (image: ImageSrc) => {
    const imageData = getImage(image)?.images
    const photoData =
        (imageData?.sources && imageData.sources[0]) || imageData?.fallback
    return {
        src: imageData?.fallback?.src,
        srcSet: photoData?.srcSet,
        sizes: photoData?.sizes
    }
}

const Image = ({
    src,
    alt,
    className,
    backgroundColor,
    onMouseEnter,
    onMouseLeave,
    loading
}: Image) => (
    <GatsbyImage
        image={getImage(src)!}
        alt={alt}
        className={className}
        backgroundColor={backgroundColor}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        loading={loading}
    />
)

export default Image

import React from 'react'
import { graphql } from 'gatsby'
import Container from 'src/components/Container'
import Layout from 'src/components/Layout'
import PageHeader from 'src/components/PageHeader'
import TeamGrid from 'src/components/TeamGrid'
import { t } from 'src/i18n/translation/t'
import {
    unwrapTeamMemberFromQuery,
    WrappedTeamMemberQueryResult
} from 'src/model/teamMemberQuery'
import { SrcImagesFragment } from 'src/page/fragments'
import { PageContext } from 'src/page/types'
import { css } from 'src/style/css'
import { Theme } from 'src/style/Theme'

const TeamPage = ({
    data,
    pageContext
}: {
    data: {
        teamImg: SrcImagesFragment
        teamMembers: WrappedTeamMemberQueryResult
    }
    pageContext: PageContext
}) => {
    const { msgs } = pageContext
    const teamMembers = unwrapTeamMemberFromQuery(data.teamMembers)

    return (
        <Layout pageCtx={pageContext}>
            <PageHeader>{t(msgs.team.title)}</PageHeader>
            <Container>
                <h2
                    css={(th: Theme) => css`
                        text-align: center;
                        font-weight: ${th.fontWeight.normal};
                        font-size: ${th.fontSize.md};
                        margin: 0 0 ${th.spacing.md};
                        line-height: 1.5;
                    `}
                >
                    {t(msgs.team.thisIsUs)}
                </h2>
                <TeamGrid items={teamMembers} />
            </Container>
        </Layout>
    )
}

export default TeamPage

export const pageQuery = graphql`
    query TeamPageData {
        teamImg: file(
            sourceInstanceName: { eq: "src-images" }
            relativePath: { eq: "team.jpg" }
        ) {
            ...SrcImages
        }
        teamMembers: teamOrderJson {
            ...TeamMembers
        }
    }
`

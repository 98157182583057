import React, { useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'
import { Helmet } from 'react-helmet'
import { css } from 'src/style/css'
import { isReferencesPage } from 'src/navigation/pages'

const ClutchWidget = () => {
    const [isReferences, setIsReferences] = useState(false)

    useEffect(() => {
        if (isReferencesPage(window.location.pathname)) {
            setIsReferences(true)
        }
    }, [])
    const th = useTheme()
    return (
        <>
            <Helmet>
                <script
                    src="https://widget.clutch.co/static/js/widget.js"
                    key="clutch-script"
                    type="text/javascript"
                    async
                />
            </Helmet>
            <div
                className="clutch-widget"
                id="clutch-widget-small"
                data-url="https://widget.clutch.co"
                data-widget-type="2"
                data-height="50"
                {...(isReferences
                    ? { 'data-lightbg': '1' }
                    : { 'data-darkbg': '1' })}
                data-clutchcompany-id="672299"
                css={css`
                    position: absolute;
                    top: calc(
                        ${th.headerHeight} + ${isReferences ? ' 20px' : ' 12px'}
                    );
                    right: ${isReferences ? th.spacing.sm : th.spacing.lg};
                    width: 200px;
                    z-index: ${th.zIndex.nav - 1};
                    @media (max-width: ${th.mediaQuery.md}) {
                        top: auto;
                        right: 50%;
                        bottom: ${isReferences ? th.spacing.xl : th.spacing.lg};
                        width: 170px;
                        transform: translate(50%, 0);
                    }
                `}
            />
        </>
    )
}

export default ClutchWidget
